import React from 'react';
import { Typography } from '@mui/material';

const PrivacyUseOfInformation = () => {
  return (
    <>
      <Typography variant="h6">2. HOW DO WE USE YOUR INFORMATION?</Typography>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          We process your information for purposes based on legitimate business interests, the fulfillment of our
          contract with you, compliance with our legal obligations, and/or your consent.
        </em>
      </p>
      <p>
        We use personal information collected via our Website for a variety of business purposes described below. We
        process your personal information for these purposes in reliance on our legitimate business interests, in order
        to enter into or perform a contract with you, with your consent, and/or for compliance with our legal
        obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
      </p>
      <p>We use the information we collect or receive:</p>
      <ul>
        <li>
          <strong>To facilitate account creation and logon process. </strong>
          If you choose to link your account with us to a third-party account (such as your Google or Facebook account),
          we use the information you allowed us to collect from those third parties to facilitate account creation and
          logon process for the performance of the contract.
        </li>
      </ul>
      <ul>
        <li>
          <strong>To post testimonials. </strong>
          We post testimonials on our Website that may contain personal information. Prior to posting a testimonial, we
          will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete
          your testimonial, please contact us at info@thsint.se and be sure to include your name, testimonial location,
          and contact information.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Request feedback. </strong>
          We may use your information to request feedback and to contact you about your use of our Website.
        </li>
      </ul>
      <ul>
        <li>
          <strong>To enable user-to-user communications. </strong>
          We may use your information in order to enable user-to-user communications with each user's consent.
        </li>
      </ul>
      <ul>
        <li>
          <strong>To manage user accounts. </strong>
          We may use your information for the purposes of managing our account and keeping it in working order.
        </li>
      </ul>
      <ul>
        <li>
          <strong>To send administrative information to you. </strong>
          We may use your personal information to send you product, service and new feature information and/or
          information about changes to our terms, conditions, and policies.
        </li>
      </ul>
      <ul>
        <li>
          <strong>To protect our Services. </strong>
          We may use your information as part of our efforts to keep our Website safe and secure (for example, for fraud
          monitoring and prevention).
        </li>
      </ul>
      <ul>
        <li>
          <strong>
            To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory
            requirements or in connection with our contract.
          </strong>
        </li>
      </ul>
      <ul>
        <li>
          <strong>To respond to legal requests and prevent harm. </strong>
          If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to
          respond.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Fulfill and manage your orders. </strong>
          We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through
          the Website.
        </li>
      </ul>
      <ul>
        <li>
          <strong>Administer prize draws and competitions. </strong>
          We may use your information to administer prize draws and competitions when you elect to participate in our
          competitions.
        </li>
      </ul>
      <ul>
        <li>
          <strong>To deliver and facilitate delivery of services to the user. </strong>
          We may use your information to provide you with the requested service.
        </li>
      </ul>
      <ul>
        <li>
          <strong>To respond to user inquiries/offer support to users. </strong>
          We may use your information to respond to your inquiries and solve any potential issues you might have with
          the use of our Services.
        </li>
      </ul>
      <ul>
        <li>
          <strong>To send you marketing and promotional communications. </strong>
          We and/or our third-party marketing partners may use the personal information you send to us for our marketing
          purposes, if this is in accordance with your marketing preferences. For example, when expressing an interest
          in obtaining information about us or our Website, subscribing to marketing or otherwise contacting us, we will
          collect personal information from you. You can opt-out of our marketing emails at any time (see the "WHAT ARE
          YOUR PRIVACY RIGHTS?" below).
        </li>
      </ul>
      <ul>
        <li>
          <strong>Deliver targeted advertising to you. </strong>
          We may use your information to develop and display personalized content and advertising (and work with third
          parties who do so) tailored to your interests and/or location and to measure its effectiveness.
        </li>
      </ul>
      <ul>
        <li>
          <strong>For other business purposes. </strong>
          We may use your information for other business purposes, such as data analysis, identifying usage trends,
          determining the effectiveness of our promotional campaigns and to evaluate and improve our Website, products,
          marketing and your experience. We may use and store this information in aggregated and anonymized form so that
          it is not associated with individual end users and does not include personal information. We will not use
          identifiable personal information without your consent.
        </li>
      </ul>
    </>
  );
};

export default PrivacyUseOfInformation;
