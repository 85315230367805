import { Typography } from '@mui/material';
import React from 'react';

const Additional: React.FC = () => (
  <>
    <Typography variant="h4">Additional Communication</Typography>
    <p>
      By agreeing to receive additional communication, you allow THS International, the operator of the sign up system,
      to send you additional emails from time to time that could be of relevance for you. These emails do not
      necessarily have any relevance for the events that you sign up. They can be for example important news about life
      at KTH/THS or job offers. These emails may be sent through an additional service provider (e.g. Mailchimp) where
      your data might be stored. Under no circumstances will THS International give out your data to external parties
      for contacting you directly. The first contact will always be through THS International. You can always change
      this preference in your profile page.
    </p>
  </>
);

export default Additional;
