import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import TabBar from '/components/tabs/Tabs';

import useIsMobile from 'hooks/useIsMobile';
import useProjectId from 'hooks/useProjectId';
import AboutUs from './aboutus/AboutUs';
import Additional from './additional/Additional';
import Privacy from './privacy/Privacy';
import Terms from './terms/Terms';

/**
 * About Us Page.
 * contains:
 *  - About Us - IT Team
 *  - Terms and conditions
 *  - Privacy policy
 *  - Additional communication
 */
const About: React.FC = () => {
  const isMobile = useIsMobile();
  const projectId = useProjectId();

  // categories in about page.
  const categories = {
    'About Us': `/${projectId}/about/aboutus`,
    'Terms and Conditions': `/${projectId}/about/terms`,
    'Privacy Policy': `/${projectId}/about/privacy`,
    'Additional Communication': `/${projectId}/about/additional`,
  };

  return (
    <>
      <TabBar tablist={categories} centeredUntil={undefined} />
      <Content sidebar={!isMobile ? categories : undefined}>
        <div style={{ marginTop: '32px' }}>
          <Routes>
            <Route path="/aboutus" element={<AboutUs projectId={projectId} />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/additional" element={<Additional />} />
            <Route path="*" element={<Navigate to="aboutus" />} />
          </Routes>
        </div>
      </Content>
      <Footer />
    </>
  );
};

export default About;
