import React from 'react';
import { Typography } from '@mui/material';

const PrivacyWhatInformationCollected = () => {
  return (
    <>
      <Typography variant="h6">1. WHAT INFORMATION DO WE COLLECT?</Typography>
      <p>
        <strong>Personal information you disclose to us</strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>We collect personal information that you provide to us.</em>
      </p>
      <p>
        We collect personal information that you voluntarily provide to us when you register on the Website, express an
        interest in obtaining information about us or our products and Services, when you participate in activities on
        the Website or otherwise when you contact us.
      </p>
      <p>
        The personal information that we collect depends on the context of your interactions with us and the Website,
        the choices you make and the products and features you use. The personal information we collect may include the
        following:
      </p>
      <p>
        <strong>Personal Information Provided by You. </strong>
        We collect names; phone numbers; email addresses; passwords; contact preferences; contact or authentication
        data; nationality; study program/type; and other similar information.
      </p>
      <p>
        All personal information that you provide to us must be true, complete and accurate, and you must notify us of
        any changes to such personal information.
      </p>
      <p>
        <strong>Information automatically collected</strong>
      </p>
      <p>
        <strong>
          <em>In Short: </em>
        </strong>
        <em>
          Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics
          &mdash; is collected automatically when you visit our Website.
        </em>
      </p>
      <p>
        We automatically collect certain information when you visit, use or navigate the Website. This information does
        not reveal your specific identity (like your name or contact information) but may include device and usage
        information, such as your IP address, browser and device characteristics, operating system, language
        preferences, referring URLs, device name, country, location, information about how and when you use our Website
        and other technical information. This information is primarily needed to maintain the security and operation of
        our Website, and for our internal analytics and reporting purposes.
      </p>
      <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
      <p>The information we collect includes:</p>
      <ul>
        <li>
          <em>Log and Usage Data. </em>
          Log and usage data is service-related, diagnostic, usage and performance information our servers automatically
          collect when you access or use our Website and which we record in log files. Depending on how you interact
          with us, this log data may include your IP address, device information, browser type and settings and
          information about your activity in the Website (such as the date/time stamps associated with your usage, pages
          and files viewed, searches and other actions you take such as which features you use), device event
          information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).
        </li>
      </ul>
      <ul>
        <li>
          <em>Device Data. </em>
          We collect device data such as information about your computer, phone, tablet or other device you use to
          access the Website. Depending on the device used, this device data may include information such as your IP
          address (or proxy server), device and application identification numbers, location, browser type, hardware
          model Internet service provider and/or mobile carrier, operating system and system configuration information.
        </li>
      </ul>
    </>
  );
};

export default PrivacyWhatInformationCollected;
